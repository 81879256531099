<style lang="scss" scoped>
.uploadMedia {
  min-height: 356px;
  max-height: 356px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.progress-outer {
  width: 430px;
  background-color: #F3F4F6;
  height: 10px;
  border-radius: 10px;
  margin: 0 auto;
}
.progress-inner {
  width: 10%;
  background-color: #E2462C;
  height: 10px;
  border-radius: 10px;
  transition: 0.2s ease;
}

</style>

<template>
  <div class="w-full uploadMedia">
    <div v-if="uploadStatus!=='complete'" class="font-g font-semibold">Upload Music</div>
    <div v-if="uploadStatus==='enter'">
      <div class="mt-4">
        <Button @click="uploadFromComputer()">
          Upload from Computer
        </Button>
        <input
          style="display: none;"
          id="uploadCustomFile"
          accept=" .m4a, .aac, .ogg, audio/opus, audio/mpeg"
          type="file"
          value="upload"
          @change="checkFile($event)"
        >
      </div>
    </div>

    <!-- Progress Bar -->
    <div class="flex flex-col mt-4 w-full items-center" v-else-if="uploadStatus === 'inprogress'">
      <div class="text-sm mb-4">
        {{ titleName }}
      </div>

      <div class="progress-outer">
        <div
          class="progress-inner"
          :style="'width:'+percentage+'%;'"
          ref="addFileProgressBar"
        />
      </div>

      <span class="font-semibold mt-6 text-sm">
        {{percentage}} %
      </span>
    </div>

    <AddFilesFooter :config="footerConfig" @actionBtnClick="actionBtnClick($event)"></AddFilesFooter>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Button from '@/components/base/buttons/Button.vue'
import debounce from 'lodash/debounce'
import { checkResource, writeToDBAboutMedia, uploadResourceToDB } from '../utility'
import store from '@/services/store'
const uuidv1 = require('uuid/v1')
const ErrorStates = {
  101: `Link invalid`,
  102: `Upload failed`
}
let file = null
export default {
  name: 'UploadMedia',
  components: {
    Button,
    AddFilesFooter: () => import('../AddFileFooter/AddFileFooter')
  },
  data () {
    return {
      titleName: '',
      uploadStatus: 'enter',
      percentage: 0,
      url: '',
      type: '',
      footerConfig: null
    }
  },
  methods: {
    actionBtnClick (e) {
      if (e.type === 'done') store.dispatch('dialogs/closeModal')
    },
    uploadFromComputer () {
      $('#uploadCustomFile').click()
    },
    uploadResource () {
      let vm = this
      if (this.titleName === '') {
        return
      }
      let resourceId = 'm-' + uuidv1().replace(/^(.{8})-(.{4})-(.{4})/, '$3-$2-$1')
      if (vm.type === 'url') {
        console.log(vm.user.uid, vm.url, vm.titleName)
        vm.writeMeta(vm.user.uid, vm.titleName, vm.url, resourceId, 'url')
      }
      if (vm.type === 'file') {
        vm.uploadStatus = 'inprogress'
        let uploadTask = uploadResourceToDB(vm.user.uid, file, resourceId)
        uploadTask.on('state_changed', function (snapshot) {
          var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          console.log('Upload is ' + progress + '% done')
          vm.percentage = progress.toFixed(2)
        }, function (err) {
          console.log(err)
        }, function () {
          const url = uploadTask.snapshot.ref.fullPath
          vm.writeMeta(vm.user.uid, vm.titleName, url, resourceId, 'file')
          console.log(vm.titleName, url)
        })
      }
    },
    writeMeta (uid, title, url, resourceId, type) {
      let vm = this
      writeToDBAboutMedia(uid, title, url, resourceId, type).then(function (res) {
        vm.uploadStatus = 'complete'
        store.commit('modal/setSelectedFile', { type: 'fresh_upload', key: resourceId })
        store.dispatch('modal/addFile', vm.isReplacing)
      }).catch(function (err) {
        console.log(err)
      })
    },
    checkURLNow () {
      let vm = this
      checkResource(vm.url, 'url').then(function (res) {
        vm.uploadStatus = 'addTitle'
        vm.type = 'url'
        vm.uploadResource()
      }).catch(function (err) {
        console.log(err, ErrorStates[101])
      })
    },
    checkURL: debounce(
      function () {
        this.checkURLNow()
      }, 1500
    ),
    checkFile (e) {
      let vm = this
      file = e.target.files[0]
      let allowedTypes = ['audio/mp3', 'audio/aac', 'audio/opus', 'audio/mpeg', 'audio/m4a', 'audio/ogg']
      if (!allowedTypes.includes(file.type)) {
        return
      }
      console.log('FILE INPUT', file)
      // checkResource(file, 'file').then(function (res) {
      vm.titleName = file.name
      vm.type = 'file'
      vm.uploadResource()
      // }).catch(function (err) {
      //   console.log(err, ErrorStates[101])
      // })
    },
    openDropbox () {
      var vm = this
      var options = {
        success: function (files) {
          console.log("Here's the file link: " + files[0].link)
          vm.url = files[0].link
          vm.titleName = files[0].name
          vm.checkURLNow()
        },
        linkType: 'direct',
        extensions: ['audio', 'video']
      }
      // eslint-disable-next-line no-undef
      window.Dropbox.choose(options)
    }
  },
  computed: {
    ...mapGetters({
      modalProps: 'dialogs/modalProps'
    }),
    isReplacing: function() {
      return this.modalProps.isReplacing
    },
    user () {
      return store.state.app.user
    },
    currentPath () {
      return this.$route.name
    }
  },
  created () {
    if (this.currentPath === 'HomeProse') {
      this.footerConfig = {
        message: 'Uploaded files wont be transcribed here.To transcribe files,use the "Transcribe" button in the top bar',
        showHereLink: false,
        actionBtns: {
          'addFile': {
            label: 'Add file',
            type: 'submit',
            isDisabled: true,
            isHidden: false
          }
        }
      }
    } else {
      this.footerConfig = {
        message: 'Uploaded files wont be transcribed here.To transcribe files,use the "Transcribe" button in the top bar',
        showHereLink: false,
        actionBtns: {
          'done': {
            label: 'Done',
            type: 'done',
            isDisabled: true,
            isHidden: false
          }
        }
      }
    }
  }
}
</script>
